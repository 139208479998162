<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      {{ heroTitle }}
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component
          :title="formCardTitle"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="No BA">
              <p class="control">
                <a class="button is-static">
                  {{ noid[0] }}
                </a>
              </p>
              <p class="control">
                <b-input v-model="noid[1]" placeholder="01" type="number" required/>
              </p>
              <p class="control">
                <b-input v-model="noid[2]" placeholder="/TE-FT/VIII/2021" type="text" required/>
              </p>
            </b-field>

            <b-field label="Judul Tugas Akhir">
              <b-input
                v-model="form.title"
                placeholder=""
                type="textarea"
                required
              />
            </b-field>
            <b-field v-if="data && currentRole === 'admin'" label="Pilih KBK">
              <v-select v-model="form.kbk_id" label="name" :options="data.kbk" :reduce="x => x.id"></v-select>
            </b-field>
            <b-field label="Pilih Mahasiswa">
              <v-select v-model="form.mahasiswa_id" label="name" :options="data.mahasiswa" :reduce="x => x.id"></v-select>
            </b-field>
            <hr>
            <b-field label="Pilih Pembimbing Pertama">
              <v-select v-model="form.pembimbing1_id" label="name" :options="data.dosen" :reduce="x => x.id"></v-select>
            </b-field>
            <b-field label="Pilih Pembimbing Kedua">
              <v-select v-model="form.pembimbing2_id" label="name" :options="data.dosen" :reduce="x => x.id"></v-select>
            </b-field>
            <hr>
            <!-- <b-field label="File Draft">
              <b-input
                v-model="form.file"
                placeholder="https://"
              />
            </b-field> -->
            <b-field>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
        <card-component
          title="Atur Sidang"
          icon="account-edit"
          class="tile is-child"
        >
          <form @submit.prevent="submit">
            <b-field label="Tanggal Sidang">
              <b-datepicker
                  v-model="form.tanggal_sidang"
                  placeholder="Pilih Tanggal"
                  icon="calendar-today"
                  locale="id-ID"
                  :date-parser="() => new date()"
                  trap-focus>
              </b-datepicker>
            </b-field>
            <b-field label="Sesi">
              <b-timepicker v-model="time.start" locale="id-ID" placeholder="mulai"></b-timepicker>
              <b-timepicker v-model="time.end" locale="id-ID" placeholder="selesai" :min-time="time.start"></b-timepicker>
            </b-field>
            <hr>
            <b-field label="Pilih Ketua Sidang">
              <v-select v-model="form.ketua_sidang_id" label="name" :options="data.dosen" :reduce="x => x.id"></v-select>
            </b-field>
            <b-field label="Pilih Penguji Pertama">
              <v-select v-model="form.penguji1_id" label="name" :options="data.dosen" :reduce="x => x.id"></v-select>
            </b-field>
            <b-field label="Pilih Penguji Kedua">
              <v-select v-model="form.penguji2_id" label="name" :options="data.dosen" :reduce="x => x.id"></v-select>
            </b-field>
            <b-field>
              <b-button
                type="is-primary"
                :loading="isLoading"
                native-type="submit"
                >Submit</b-button
              >
            </b-field>
          </form>
        </card-component>
      </tiles>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardComponent from '@/components/CardComponent'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Form',
  components: {
    CardComponent,
    Tiles,
    HeroBar,
    TitleBar,
    vSelect
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      form: this.getClearFormObject(),
      isProfileExists: false,
      data: {
        kbk: [],
        mahasiswa: [],
        dosen: []
      },
      time: {},
      noid: [
        'BTA-',
        '',
        ''
      ]
    }
  },
  computed: {
    currentRole () {
      return this.$store.getters.currentRole
    },
    titleStack () {
      let lastCrumb

      if (this.isProfileExists) {
        lastCrumb = this.form.name
      } else {
        lastCrumb = 'Tambah Tugas Akhir'
      }

      return ['Dashboard', 'Tugas Akhir', lastCrumb]
    },
    heroTitle () {
      if (this.isProfileExists) {
        return this.form.name
      } else {
        return 'Tambah Tugas Akhir'
      }
    },
    heroRouterLinkTo () {
      if (this.isProfileExists) {
        return { name: 'mahasiswa.create' }
      } else {
        return '/'
      }
    },
    heroRouterLinkLabel () {
      if (this.isProfileExists) {
        return 'Tambah Tugas Akhir'
      } else {
        return 'Dashboard'
      }
    },
    formCardTitle () {
      if (this.isProfileExists) {
        return 'Edit Tugas Akhir'
      } else {
        return 'Tambah Tugas Akhir'
      }
    }
  },
  watch: {
    id (newValue) {
      this.isProfileExists = false

      if (!newValue) {
        this.form = this.getClearFormObject()
      } else {
        this.getData()
      }
    }
  },
  mounted () {
    this.getOptions()
  },
  created () {
    this.getData()
  },
  methods: {
    getOptions () {
      axios
        .get('/mahasiswa')
        .then((r) => {
          this.data.mahasiswa = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/dosen')
        .then((r) => {
          this.data.dosen = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      axios
        .get('/kbk')
        .then((r) => {
          this.data.kbk = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    getClearFormObject () {
      return {
        title: null,
        kbk_id: null,
        mahasiswa_id: null,
        pembimbing1_id: null
      }
    },
    getData () {
      if (this.$route.params.id) {
        const id = parseInt(this.$route.params.id)
        axios
          .get(`/thesis/${id}`)
          .then((r) => {
            console.log(r.data)
            if (r.data.status === 'ok') {
              r.data.data.tanggal_sidang = r.data.data.tanggal_sidang ? new Date(r.data.data.tanggal_sidang) : null
              this.form = r.data.data
              this.form.kbk_id = parseInt(this.form.kbk_id)
              this.form.mahasiswa_id = parseInt(this.form.mahasiswa_id)
              this.form.pembimbing1_id = parseInt(this.form.pembimbing1_id)
              this.form.pembimbing2_id = parseInt(this.form.pembimbing2_id)
              this.form.penguji1_id = parseInt(this.form.penguji1_id)
              this.form.penguji2_id = parseInt(this.form.penguji2_id)
              this.form.ketua_sidang_id = parseInt(this.form.ketua_sidang_id)

              if (this.form.noid) {
                let splitNOID = this.form.noid.split('-')
                splitNOID = splitNOID[1].split('/')
                this.noid[1] = splitNOID[0]
                this.noid[2] = '/' + splitNOID[1]
              }

              if (this.form.sesi) {
                const sesi = this.form.sesi.split('-').join(':').split(':')
                const start = new Date()
                start.setHours(sesi[0])
                start.setMinutes(sesi[1])

                const end = new Date()
                end.setHours(sesi[2])
                end.setMinutes(sesi[3])

                this.time.start = start
                this.time.end = end
              }

              this.isProfileExists = true
            }
          })
          .catch((e) => {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              queue: false
            })
          })
      }
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })

      if (r.data.status === 'ok') {
        this.$router.push({ name: 'thesis' })
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submit () {
      const data = { ...this.form }

      data.noid = this.noid.join('')

      if (this.time.start && this.time.end) {
        data.sesi = `${this.time.start.getHours()}:${this.time.start.getMinutes()}-${this.time.end.getHours()}:${this.time.end.getMinutes()}`
      }

      if (data.tanggal_sidang) {
        data.tanggal_sidang.setHours(data.tanggal_sidang.getHours() + 7)
        data.tanggal_sidang = data.tanggal_sidang.toISOString().split('T')[0]
      }

      if (this.currentRole === 'adminkbk' && this.$store.getters.currentKBK) {
        data.kbk_id = this.$store.getters.currentKBK.id
      }

      this.isLoading = true

      if (this.$route.params.id) {
        axios
          .put(`/thesis/${this.$route.params.id}`, data)
          .then(this.handleResponse)
          .catch(this.handleError)
      } else {
        axios
          .post('/thesis', data)
          .then(this.handleResponse)
          .catch(this.handleError)
      }
    }
  }
}
</script>
